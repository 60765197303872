export default [

  // {
  //   title: 'Availability',
  //   route: 'availability',
  //   icon: 'CompassIcon',
  // },
  {
    title: 'Tour',
    route: 'tour',
    icon: 'CompassIcon',
  },

  {
    title: 'Vendors',
    route: 'vendors',
    icon: 'UsersIcon',
  },
  {
    title: 'Add-ons',
    route: 'addons',
    icon: 'PackageIcon',
  },
  {
    title: 'Pickup Time',
    route: 'pickuptime',
    icon: 'ClockIcon',
  },
  {
    title: 'Suggestion',
    route: 'suggestion',
    icon: 'FileTextIcon',
  },
]
