<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
<!--          <NotificationDropdown />-->
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ fullName }}
            </p>
            <span class="user-status">{{ userGroupName }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="auth.accountInfo.gender == 'F'
              ? require('@/assets/images/avatars/15-small.jpg')
              : require('@/assets/images/avatars/14-small.jpg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          v-if="auth.accountInfo.is_admin"
          link-class="d-flex align-items-center"
          :to="{ name: 'tour' }"
        >
          <feather-icon
            size="16"
            icon="HomeIcon"
            class="mr-50"
          />
          <span>Home</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'settings'}"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import api from '@/libs/api'
import account from '@/mixins/account'

export default {
  components: {
    // Navbar Components
    DarkToggler,
    // NotificationDropdown,
  },
  mixins: [account],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      auth: this.$store.state.auth,
      hotelURL: process.env.VUE_APP_HOTEL_URL,
      corporateURL: process.env.VUE_APP_CORPORATE_URL,
    }
  },
  computed: {
    fullName() {
      return this.$store.state.auth.accountInfo && this.$store.state.auth.accountInfo.first_name
        ? `${this.$store.state.auth.accountInfo.first_name} ${this.$store.state.auth.accountInfo.last_name}`
        : 'John Doe'
    },
    userGroupName() {
      const { accountInfo } = this.$store.state.auth
      if (accountInfo.is_admin) {
        return 'Super Duper Admin'
      }
      if (accountInfo.is_hotel_admin) {
        return 'Hotel Admin'
      }
      return 'Meh Admin'
    },
  },
  methods: {
    redirectToPanel(url, type) {
      if (type === 'hotel') {
        window.open(`${url}?id=${this.auth.Token}&expiry=${this.auth.Expiry}&panel=hotel`)
      } else if (type === 'tour') {
        window.open(`${url}?id=${this.auth.Token}&expiry=${this.auth.Expiry}&panel=tour`)
      } else if (type === 'corporate') {
        window.open(`${url}?id=${this.auth.Token}&expiry=${this.auth.Expiry}`)
      }
    },
    logout() {
      api.postData('auth/logout/', true, {}).then(response => {
        if (response.status === 204) {
          this.clearUserData()
          this.$router.push('/login')
        }
      }).catch()
    },
  },
}
</script>
